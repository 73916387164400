import { DevUtils } from '@meilleursbiens/utils';

import moment from 'moment';

import packageJson from '../../../../package.json';

export default class StringUtils {
  static getVersion() {
    return packageJson.version;
  }

  static initCrisp() {
    if (window.$crisp) return;
    if (window.location.pathname.includes('/pro/support/crisp-widget')) return;

    window.$crisp = [];
    window.CRISP_WEBSITE_ID = 'f22a03f9-bda1-458d-8289-319e1a8148fb';

    var d = document;
    var s = d.createElement('script');

    s.src = 'https://client.crisp.chat/l.js';
    s.async = 1;
    d.getElementsByTagName('head')[0].appendChild(s);
    window.$crisp.push(['safe', true]);
  }

  static asciiConsole() {
    console.log(
      `
  __  __      _ _ _                     ____  _
 |  \\/  | ___(_) | | ___ _   _ _ __ ___| __ )(_) ___ _ __  ___
 | |\\/| |/ _ \\ | | |/ _ \\ | | | '__/ __|  _ \\| |/ _ \\ '_ \\/ __|
 | |  | |  __/ | | |  __/ |_| | |  \\__ \\ |_) | |  __/ | | \\__ \\
 |_|  |_|\\___|_|_|_|\\___|\\__,_|_|  |___/____/|_|\\___|_| |_|___/

`
    );
    console.log('Version : ' + StringUtils.getVersion());
    console.log('Environnement : ' + (DevUtils.isDev() ? 'DEV' : 'PROD'));
    console.log('Date : ' + new Date().toISOString());
    console.log('Timezone: ' + Intl.DateTimeFormat().resolvedOptions().timeZone);
    console.log('---------------------------------------');
  }

  static formatPrice(price) {
    try {
      var formatter = new Intl.NumberFormat('fr-FR', {
        style: 'currency',
        currency: 'EUR',
        maximumFractionDigits: 0,
      });

      return formatter.format(price);
    } catch (e) {
      return price;
    }
  }

  static formatPriceToThousand(price) {
    try {
      let priceRounded = Math.round(price / 1000) * 1000;

      var formatter = new Intl.NumberFormat('fr-FR', {
        style: 'currency',
        currency: 'EUR',
        maximumFractionDigits: 0,
      });

      return formatter.format(priceRounded);
    } catch (e) {
      return price;
    }
  }

  static truncate(str, n) {
    return str.length > n ? str.substr(0, n - 1) + '...' : str;
  }

  static formatPriceWithDecimals(price) {
    try {
      var formatter = new Intl.NumberFormat('fr-FR', {
        style: 'currency',
        currency: 'EUR',
        maximumFractionDigits: 2,
      });

      return formatter.format(price);
    } catch (e) {
      return price;
    }
  }

  static formatPhoneNumber(user) {
    return '+' + user.phoneCode + user.phone.substr(1);
  }

  static splitEvery2Char(str) {
    let match = str.match(/.{1,2}/g);

    return match.join(' ');
  }

  static getFactureTransactionPrice(transaction) {
    try {
      let transaction_facture = transaction.facture;

      let produits = JSON.parse(transaction_facture.produits);

      let tva = parseFloat(transaction.tva);

      if (tva == 0) {
        return this.formatPriceWithDecimals(produits[0].price);
      } else return this.formatPriceWithDecimals(produits[0].price + produits[0].price * (tva / 100));
    } catch (e) {
      return 'Montant invalide !';
    }
  }

  static getFactureTransactionLocPrice(transaction, type = 'bailleur') {
    try {
      let transaction_facture;

      if (type == 'bailleur') {
        transaction_facture = transaction.facture_bailleur;
      } else {
        transaction_facture = transaction.facture_locataire;
      }

      let produits = JSON.parse(transaction_facture.produits);

      let tva = parseFloat(transaction.tva);

      if (tva == 0) {
        return this.formatPriceWithDecimals(produits[0].price);
      } else return this.formatPriceWithDecimals(produits[0].price);
    } catch (e) {
      return 'Montant invalide !';
    }
  }

  static getFileExtension(file) {
    const re = /(?:\.([^.]+))?$/;
    const result = re.exec(file.toLowerCase());
    if (result.length < 2) return 'pdf';
    return result[1];
  }

  static getInvoiceName(invoice) {
    let type = invoice.type;
    let date = undefined;
    try {
      date = moment(invoice.created_at, 'DD/MM/YYYY').format('YYYYMMDD');
    } catch (e) {
      date = '';
    }

    switch (type) {
      case 0:
        return 'V' + invoice.id;
      case 1:
        return 'V' + invoice.id;
      case 2:
        return 'L' + invoice.id;
      case 4:
        return 'E' + invoice.id;
    }
  }

  static humanFileSize(bytes, si = false, dp = 1) {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
      return bytes + ' B';
    }

    const units = si
      ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
      : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10 ** dp;

    do {
      bytes /= thresh;
      ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

    return bytes.toFixed(dp) + ' ' + units[u];
  }

  static getHelloByTime() {
    let hour = new Date().getHours();
    if (hour < 12) {
      return 'Bonjour';
    } else if (hour < 18) {
      return 'Bon après-midi';
    } else {
      return 'Bonsoir';
    }
  }

  static randomString(length) {
    let result = '';
    let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let charactersLength = characters.length;

    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
  }

  static groupByKey(array, key) {
    return array.reduce((hash, obj) => {
      if (obj[key] === undefined) return hash;
      return Object.assign(hash, {
        [obj[key]]: (hash[obj[key]] || []).concat(obj),
      });
    }, {});
  }

  static matchYoutubeUrl(url) {
    const p = /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:watch\?v=|shorts\/)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const matches = url.match(p);
    return !!matches;
  }

  static matchVimeoUrl(url) {
    const p =
      /(http|https)?:\/\/(www\.|player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|video\/|)(\d+)(?:|\/\?)/;
    const matches = url.match(p);
    return !!matches;
  }
}
