interface ServicesConstants {
  ALGOLIA: {
    APP_ID: string;
    SEARCH_API_KEY: string;
    MONITORING_API: string;
  };
  AWS: {
    S3: string;
    S3_STAGING: string;
    S3_PORTAIL: string;
    S3_ESTIMATION: string;
  };
  MAPBOX: {
    PUBLIC_TOKEN: string;
  };
  GOOGLE: {
    API_KEY: string;
    RECAPTCHA_PROD: string;
    RECAPTCHA_STAGING: string;
  };
  YOUTUBE: {
    API_KEY: string;
  };
  MEILLEURSBIENS: {
    API_URL: string;
    API_URL_LIVE: string;
    API_URL_DEMO: string;
    PRO_URL: string;
    STAGING_URL: string;
  };
  QONTO: {
    URL: string;
    SCA_URL: string;
  };
  PASSWORDLESS: {
    API_KEY: string;
  };
  PUSHER: {
    APP_KEY: string;
    HOST: string;
    PORT: number;
  };
}

export default <ServicesConstants>{
  ALGOLIA: {
    APP_ID: 'plPQ9O94GB8O',
    SEARCH_API_KEY: 'fc98b268d202438d04d0dd49f1fb1cfc',
    MONITORING_API: '4ecb0d51b6f74c7d4868c1c2d2eba9ee',
  },
  AWS: {
    S3: 'https://mbpro.s3.eu-west-3.amazonaws.com/',
    S3_STAGING: 'https://mbpro.s3.eu-west-3.amazonaws.com/',
    S3_PORTAIL: 'https://mb-portail.s3.eu-west-3.amazonaws.com/',
    S3_ESTIMATION: 'https://mb-estimation.s3.eu-west-3.amazonaws.com/',
  },
  MAPBOX: {
    PUBLIC_TOKEN:
      'pk.eyJ1IjoibWVpbGxldXJzYmllbnNpbW1vIiwiYSI6ImNrYnJva3N0OTJ5NnMyeWw5czEyNGF4cWkifQ.5PHBkbj4G7DEnSLN221TJA',
  },
  GOOGLE: {
    API_KEY: 'AIzaSyA8-Z5tO1eO5vAdgLYu-ltE4F620Mq-8QI',
    RECAPTCHA_PROD: '6Leikt0hAAAAABFbKU7vMTOnw7YWW3Ygog3eQtGX',
    RECAPTCHA_STAGING: '6Le5lt0hAAAAAIFUWygSNzjEdd8QQdXP5bN4s5J9',
  },
  YOUTUBE: {
    API_KEY: 'AIzaSyB3ZmWIEoVxC26MZf2rvmyU-hq0x6dyJI8',
  },
  MEILLEURSBIENS: {
    API_URL: 'https://staging.meilleursbiens.pro/api',
    API_URL_LIVE: 'https://api.meilleursbiens.com/api',
    API_URL_DEMO: 'https://staging.meilleursbiens.pro/api',
    PRO_URL: 'https://api.meilleursbiens.com',
    STAGING_URL: 'https://staging.meilleursbiens.pro',
  },
  QONTO: {
    URL: 'https://thirdparty.qonto.com/v2',
    SCA_URL: 'https://auth.qonto.com/v3',
  },
  PASSWORDLESS: {
    API_KEY: 'meilleursbiens:public:2b8f8491165d4eaf998fb402556f5b17',
  },
  PUSHER: {
    APP_KEY: 'AQCVDBCj2GShdXozHUo3o223m9pF6kvi',
    HOST: 'socket.meilleursbiens.com',
    PORT: 443,
  },
};
