import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';

import { useTeamPermission } from '@meilleursbiens/permissions';
import { useTenant, useTenantFeature } from '@meilleursbiens/tenants';
import {
  IconButton,
  NavGroup,
  NavItem,
  Persona,
  Sidebar,
  SidebarOverlay,
  SidebarSection,
  SidebarToggleButton,
  Toolbar,
  useSidebarToggleButton,
} from '@meilleursbiens/ui';

import {
  Box,
  Divider,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Portal,
  Spacer,
  useColorMode,
  useColorModeValue,
} from '@chakra-ui/react';
import {
  ArrowLeftIcon,
  ArrowPathIcon,
  ArrowRightOnRectangleIcon,
  ArrowUturnLeftIcon,
  ArrowsPointingInIcon,
  ArrowsPointingOutIcon,
  Bars4Icon,
  ChatBubbleLeftRightIcon,
  EllipsisHorizontalCircleIcon,
  EyeIcon,
  EyeSlashIcon,
  MagnifyingGlassIcon,
  MoonIcon,
  SunIcon,
} from '@heroicons/react/24/outline';

import ADMIN_NAVIGATION from '../../../../constants/ADMIN_NAVIGATION';
import { secretData } from '../../../../redux/actions/auth/AuthActions';
import { Routes } from '../../../../routes/routes';
import LocalStorageUtils from '../../../../utils/LocalStorageUtils';

export interface AdminSidebarLayoutComponentProps {
  secretData: boolean;
  setSecretData: (data: boolean) => void;
  onSearch: () => void;
  onDeviceChange: (isMobile: boolean) => void;
}

function AdminSidebarLayoutComponent(props: AdminSidebarLayoutComponentProps) {
  const location = useLocation();

  const { tenant } = useTenant();
  const { isFeatureEnabled } = useTenantFeature();
  const { hasPermissions } = useTeamPermission();

  const { colorMode, toggleColorMode } = useColorMode();
  const borderColor = useColorModeValue('gray.50', 'gray.600');
  const hoverTextColor = useColorModeValue('black', 'white');
  const borderTopColor = useColorModeValue('gray.100', 'gray.700');

  const { isMobile, isOpen, toggleSidebar } = useSidebarToggleButton();

  useEffect(() => {
    props.onDeviceChange(isMobile);
  }, []);

  useEffect(() => {
    props.onDeviceChange(isMobile);
  }, [isMobile]);

  return (
    <Sidebar
      variant={isOpen ? 'default' : isMobile ? 'default' : 'default'}
      transition="width"
      transitionDuration="normal"
      minWidth="auto"
      pt={0}
      pb={0}
    >
      {!isOpen && isMobile ? (
        <SidebarToggleButton isRound={true} icon={<Bars4Icon width={20} strokeWidth={2} />} />
      ) : null}

      <SidebarSection flex="1" overflowY="auto" overflowX="hidden" style={{ marginTop: 0 }} px={0}>
        <Toolbar h={'58px'} position={'sticky'} top={0}>
          <Persona
            size={'sm'}
            name={tenant.name}
            secondaryText={tenant.domain}
            avatarUrl={tenant.logo_square}
            avatarProps={{
              borderRadius: 'md',
              boxShadow: 'base',
              borderWidth: '1px',
              borderColor: borderColor,
            }}
          />

          <Spacer />

          <Menu closeOnSelect={false} isLazy={true}>
            <MenuButton
              autoFocus={false}
              as={IconButton}
              icon={EllipsisHorizontalCircleIcon}
              variant={'ghost'}
              size={'md'}
            />
            <Portal>
              <MenuList>
                <MenuItem icon={<MagnifyingGlassIcon width={17} strokeWidth={2} />} onClick={props.onSearch}>
                  Rechercher sur le back-office
                </MenuItem>
                <MenuDivider />
                <MenuItem
                  icon={
                    colorMode === 'dark' ? (
                      <MoonIcon width={17} strokeWidth={2} />
                    ) : (
                      <SunIcon width={17} strokeWidth={2} />
                    )
                  }
                  onClick={toggleColorMode}
                >
                  Mode {colorMode === 'dark' ? 'sombre' : 'clair'}
                </MenuItem>
                <MenuItem
                  icon={
                    props.secretData ? (
                      <EyeIcon width={17} strokeWidth={2} />
                    ) : (
                      <EyeSlashIcon width={17} strokeWidth={2} />
                    )
                  }
                  onClick={() => props.setSecretData(!props.secretData)}
                >
                  {props.secretData ? 'Afficher' : 'Cacher'} les montants
                </MenuItem>
                <MenuDivider />
                <MenuItem
                  icon={
                    isOpen ? (
                      <ArrowsPointingInIcon width={17} strokeWidth={2} />
                    ) : (
                      <ArrowsPointingOutIcon width={17} strokeWidth={2} />
                    )
                  }
                  onClick={toggleSidebar}
                >
                  {isOpen ? 'Réduire' : 'Agrandir'} le menu
                </MenuItem>
                <MenuItem icon={<ArrowPathIcon width={17} strokeWidth={2} />} onClick={() => window.location.reload()}>
                  Recharger la page
                </MenuItem>
                <MenuDivider />
                <MenuItem
                  as={Link}
                  to={Routes.PRO_DASHBOARD.url}
                  icon={<ArrowUturnLeftIcon width={17} strokeWidth={2} />}
                  _hover={{ color: hoverTextColor }}
                >
                  Retour à mon espace
                </MenuItem>
                <MenuItem
                  icon={<ArrowRightOnRectangleIcon width={17} strokeWidth={2} />}
                  onClick={() => {
                    LocalStorageUtils.clear();
                    window.location.reload();
                  }}
                >
                  Se déconnecter
                </MenuItem>
              </MenuList>
            </Portal>
          </Menu>
        </Toolbar>

        <Box px={3} py={2} pb={5}>
          {ADMIN_NAVIGATION.map((l, i) => {
            // eslint-disable-next-line no-prototype-builtins
            if (l.hasOwnProperty('feature')) {
              if (!isFeatureEnabled(l.feature)) return null;
            }
            // eslint-disable-next-line no-prototype-builtins
            const hasPermission = l.hasOwnProperty('permission') ? hasPermissions(l.permission) : true;

            // eslint-disable-next-line no-prototype-builtins
            if (l.hasOwnProperty('children')) {
              return (
                <NavGroup key={'sidebar-group-' + i} title={l.label} defaultIsOpen={!l.collapseByDefault} isCollapsible>
                  {l.children.map((c, i) => {
                    // eslint-disable-next-line no-prototype-builtins
                    if (c.hasOwnProperty('feature')) {
                      if (!isFeatureEnabled(c.feature)) return null;
                    }

                    // eslint-disable-next-line no-prototype-builtins
                    const hasPermission = c.hasOwnProperty('permission') ? hasPermissions(c.permission) : true;

                    return (
                      <NavItem
                        key={'sidebar-item-' + i}
                        icon={c.icon}
                        isActive={location.pathname === c.href}
                        as={Link}
                        to={hasPermission ? c.href : '#'}
                        id={c.id || 'sidebar-item-' + i}
                        isDisabled={!hasPermission}
                      >
                        {c.label}
                      </NavItem>
                    );
                  })}
                </NavGroup>
              );
            } else {
              return (
                <NavItem
                  key={'sidebar-gitem-' + i}
                  icon={l.icon}
                  isActive={location.pathname === l.href}
                  as={Link}
                  to={hasPermission ? l.href : '#'}
                  id={l.id || 'sidebar-item-' + i}
                  isDisabled={!hasPermission}
                  badgeProps={{ colorScheme: 'purple', fontSize: 10 }}
                  {...(l.badge ? { badge: l.badge } : {})}
                >
                  {l.label}
                </NavItem>
              );
            }
          })}
        </Box>
      </SidebarSection>
      <SidebarSection
        py={2}
        borderTop={'1px solid'}
        borderTopColor={borderTopColor}
        style={{ marginTop: 0 }}
        boxShadow={'0px -10px 18px -8px rgba(0,0,0,0.05)'}
      >
        <NavItem
          icon={ChatBubbleLeftRightIcon}
          as={Link}
          to={Routes.SUPPORT_ADMIN_PRO_LIST.url}
          badge={'Nouveau'}
          badgeProps={{ colorScheme: 'purple' }}
          isDisabled={!hasPermissions(['back.office.support.list'])}
        >
          Support Client
        </NavItem>
        <Divider pt={2} />
        <NavItem mt={2} icon={ArrowLeftIcon} as={Link} to={Routes.PRO_DASHBOARD.url}>
          Retour vers l'espace agent
        </NavItem>
      </SidebarSection>
      <SidebarOverlay zIndex="1" />
    </Sidebar>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    setSecretData: (data) => {
      dispatch(secretData(data));
    },
  };
};

const mapStateToProps = (state) => ({
  secretData: state.auth.secretData,
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminSidebarLayoutComponent);
